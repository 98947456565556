import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AvatarModule, PipesModule } from '@ekipa/common-ui';
import { TranslocoModule } from '@ngneat/transloco';
import { HeaderChallengeDropdownComponent } from './header-challenge-dropdown/header-challenge-dropdown.component';
import { HeaderNavItemsComponent } from './header-nav-items/header-nav-items.component';
import { HeaderProfileDropdownComponent } from './header-nav-items/header-profile-dropdown/header-profile-dropdown.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent, HeaderNavItemsComponent, HeaderProfileDropdownComponent],
  imports: [
    HeaderChallengeDropdownComponent,
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatIconModule,
    TranslocoModule,
    PipesModule,
    AvatarModule,
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
